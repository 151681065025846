import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Convenios from 'components/convenios'

const ConveniosPage = ({ data, location, pageContext }) => {
  const wordpressData = data.wordpressWpGatsbyPage.acf

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={wordpressData.title}
        description={wordpressData.subtitle}
        canonical={wordpressData.canonical}
        pathName={location.pathname}
      />
      <Convenios data={data} />
    </Layout>
  )
}

ConveniosPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default ConveniosPage

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        title
        subtitle
        demonstrative_text
        canonical
        complementary_text
        title_right_column
        description_right_column
      }
    }
  }
`
