import React from 'react'
import ContactCards from '../contact/contactCards'
import Img from 'gatsby-image'
import * as S from './styled'

const CardsDesktop = (props) => {
    return (
        <>
        <S.LeftColumnContainer>
            <S.TitleLeftColumn>
              {props.text}
            </S.TitleLeftColumn>

            <S.Images>
                {props.items.map((item) => (
                    <S.ImgContent>
                        <Img 
                            fluid={item.imagem_do_convenio.localFile.childImageSharp.fluid}
                            alt={item.imagem_do_convenio.alt_text}
                        />
                    </S.ImgContent>
                ))}
            </S.Images>
            
        </S.LeftColumnContainer>

        <S.RightColumnContainer>
            <S.TitleColumRight>
                {props.titleRightColumn}
            </S.TitleColumRight>

            <S.DescriptionColumRight>
                {props.descriptionRightColum}
            </S.DescriptionColumRight>

            <S.ContactCardsContent>
                <ContactCards width="26.4rem" />
            </S.ContactCardsContent>
        </S.RightColumnContainer>
        </>
    )
}

export default CardsDesktop
