import React from 'react'
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import ContactCards from '../contact/contactCards'
import Img from 'gatsby-image'

import * as S from './styled'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/pagination/pagination.min.css'

SwiperCore.use([Pagination]);

const CardsMobile = (props) => {
    return (
        <>
            <S.MobileContainer>
                <S.TitleLeftColumn>
                {props.text}
                </S.TitleLeftColumn>

                <S.CarouselMobileContainer>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={"auto"}
                        modules={[Pagination]}
                        pagination={{
                            clickable: true,
                        }}
                    >
                        {props.items.map((data) => (
                            <SwiperSlide>
                                {data.map((item) => (
                                    <S.ImgContent>
                                        <Img 
                                            fluid={item.imagem_do_convenio.localFile.childImageSharp.fluid}
                                            alt={item.imagem_do_convenio.alt_text}
                                        />
                                    </S.ImgContent>
                                ))}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </S.CarouselMobileContainer>
            </S.MobileContainer>

            <S.RightColumnContainer>
                <S.TitleColumRight>
                    {props.titleRightColumn}
                </S.TitleColumRight>

                <S.DescriptionColumRight>
                    {props.descriptionRightColum}
                </S.DescriptionColumRight>

                <S.ContactCardsContent>
                    <ContactCards />
                </S.ContactCardsContent>
            </S.RightColumnContainer>
        </>
    )
}

export default CardsMobile
