import styled from 'styled-components'
import media from 'styled-media-query'
import { ContainerDasa } from '../shared/container'
import { PinkLine } from '../shared/PinkLine/styled'
import { ContactCards } from '../contact/styled'
import { CarouselContainer } from '../carousel/styled'
import { alvTokens } from '@dasa-health/alma-tokens'

export const ContainerHealthInsurance = styled.div`
  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    padding: 0 1.6rem;
  `}
`

export const ContainerPage = styled(ContainerDasa)`
  display: flex;
  flex-wrap: wrap;

  ${media.lessThan('medium')`
    justify-content:center;
    padding: 0;
  `}
`

export const LeftColumnContainer = styled.div`
  padding-right: 6.8rem;
  border-right: 1px solid ${alvTokens.ColorNeutral20};

  ${media.lessThan('medium')`
    padding-right: 0;
    border: 0;
  `}
`

export const TitleHealthInsurance = styled.h1`
  font-size: 40px;
  color: ${props => props.theme.colors.brand.primary[10]};
  line-height: 133%;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1px;
  margin: 0 0 2.4rem;
  width: 100%;
  
  ${media.lessThan('medium')`
    font-size: 20px;
    margin: 0 0 2.4rem 0;
  `}
`

export const SubtitleHealthInsurance = styled.p`
  font-size: 14px;
  color: ${alvTokens.ColorPaletteGray30};
  line-height: 170%;
  font-weight: 400;
  margin: 0 0 3.2rem;
  max-width: 75.9rem;
  
  ${media.lessThan('medium')`
    margin: 0 0 6.4rem 0;
    color: ${alvTokens.ColorPaletteGray70};
  `}
`

export const ContentHealthInsurance = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    max-width: calc(100vw - 3.2rem);
    margin: 0 auto;
    flex-direction: column;
  `}
`

export const RightColumnContainer = styled.div`
  padding-left: 6.8rem;

  ${media.lessThan('medium')`
    width:100%;
    max-width: calc(100vw - 3.2rem);
    margin: 0 auto;
    padding: 0;
  `}
`
export const RightColumnText = styled.p`
  font-size: 14px;
  color: ${alvTokens.ColorPaletteGray30};
  line-height: 170%;
  padding: 30px 0 20px 30px;
  word-wrap: break-word;
  margin: 0;

  ${media.lessThan('medium')`
    padding:0 0 20px 0;
  `}
`

export const TitleLeftColumn = styled.h2`
  font-size: 20px;
  line-height: 120%;
  font-weight: 700;
  color: ${props => props.theme.colors.palette.black};
  margin: 0 0 2.4rem;
`

export const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.4rem;
`

export const ImgContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 14.2rem;
  background: var(--white);
  border: 2px solid ${alvTokens.ColorNeutral20};
  border-radius: 8px;
  
  .gatsby-image-wrapper {
    width: 15rem;
    height: 12rem;
    filter: grayscale(100%);
    filter: gray; /* IE */

    ${media.lessThan('medium')`
      width: 12.8rem;
      height: 11.5rem;
    `}
  }

  ${media.lessThan('medium')`
    background: var(--neutral00);
  `}
`

export const DescriptionHealthInsurance = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: ${alvTokens.ColorPaletteGray30};
  max-width: 64.6rem;
  margin: 4rem 0 25.6rem;
  
  ${media.lessThan('medium')`
    margin: 2.4rem 0 3.2rem;
    color: ${alvTokens.ColorPaletteGray70};

    a {
      color: ${alvTokens.ColorBrandPrimary10};
    }
  `}

  a {
    color: ${props => props.theme.colors.brand.primary[10]};
  }
`

export const TitleColumRight = styled.p`
   font-weight: 700;
   font-size: 20px;
   line-height: 120%;
   color: ${props => props.theme.colors.palette.black};
   margin: 0 0 2.4rem;
`

export const DescriptionColumRight = styled.p`
   font-weight: 400;
   font-size: 14px;
   line-height: 170%;
   color: ${alvTokens.ColorPaletteGray70};
   margin: 0 0 3.2rem;
   
   ${media.lessThan('medium')`
    margin: 0 0 2.4rem;
  `}
`

export const ContactCardsContent = styled(ContactCards)`
  flex-direction: column;
  margin-bottom: 0;
`
export const Line = styled(PinkLine)`
  ${media.lessThan('medium')`
    margin: 0 -1.6rem;
    width: 100vw;
  `}
`

export const CarouselMobileContainer = styled(CarouselContainer)`
  margin-bottom: 6.4rem;

  .swiper-container {
    min-height: 34.8rem;
    margin-left: 0;

    .swiper-wrapper {
      .swiper-slide {
        max-width: 22.2rem;

        div {
          margin: 0 0 2.4rem;
  
          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .swiper-pagination {
      &.swiper-pagination-bullets,
      &.swiper-pagination-clickable {
        .swiper-pagination-bullet {
          background: rgba(22, 22, 22, 0.24);
        }

        .swiper-pagination-bullet-active {
          background: ${alvTokens.ColorNeutral20};
          box-shadow: none;
        } 
      }
    }
  }
`
export const MobileContainer = styled.div``