import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { isMobile } from '@cms/utils'
import arrayPagination from '../../utils/arrayPaginate'
import * as S from './styled'

import CardsDesktop from './cardsDesktop'
import CardsMobile from './cardsMobile'

const Convenios = ({ data }) => {
  const dataQuery = useStaticQuery(graphql`
    query {
      allWordpressWpAgreementsPage {
        edges {
          node {
            acf {
              titulo_convenio
              ordem_de_exibicao_do_convenio
              link_do_convenio
              imagem_do_convenio {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const healthData = dataQuery.allWordpressWpAgreementsPage.edges
  const [healthInsuranceData, setHealthInsuranceData] = useState([])

  const {
    wordpressWpGatsbyPage: {
      acf: { 
        title,
        subtitle,
        demonstrative_text: demonstrativeText,
        complementary_text: complementaryText,
        title_right_column: titleRightColumn,
        description_right_column: descriptionRightColum
      },
    },
  } = data

  function orderHealthInsurance(a, b) {
    return a.ordem_de_exibicao_do_convenio - b.ordem_de_exibicao_do_convenio
  }

  useEffect(() => {
    let results = []

    healthData.map(result => results.push(result.node.acf))

    results.sort(orderHealthInsurance)

    setHealthInsuranceData(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const HealthInsuranceMobile = arrayPagination(healthInsuranceData, 2);

  const [breakpoint, setBreakpoint] = useState(isMobile());

    function breakPointChecker() {
        if (isMobile() === breakpoint) return

        setBreakpoint(isMobile())
    }

    if (typeof window !== `undefined`) {
        window.onresize = function () {
            breakPointChecker();
        }
    }

  return (
    <S.ContainerHealthInsurance>
      <S.ContainerPage>
        <S.TitleHealthInsurance>{title}</S.TitleHealthInsurance>
        <S.SubtitleHealthInsurance>{subtitle}</S.SubtitleHealthInsurance>
        <S.ContentHealthInsurance>
          {
            !breakpoint ? (
              <CardsDesktop 
                items={healthInsuranceData} 
                text={complementaryText}
                titleRightColumn={titleRightColumn}
                descriptionRightColum={descriptionRightColum}
              />
            ) : (
              <CardsMobile
                items={HealthInsuranceMobile} 
                text={complementaryText}
                titleRightColumn={titleRightColumn}
                descriptionRightColum={descriptionRightColum}
              />
            )
          }
        </S.ContentHealthInsurance>

        <S.DescriptionHealthInsurance
          dangerouslySetInnerHTML={{ __html: demonstrativeText }}
        >
        </S.DescriptionHealthInsurance>
      </S.ContainerPage>
      <S.Line />
    </S.ContainerHealthInsurance>
  )
}

export default Convenios
