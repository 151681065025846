import React from 'react'
import * as S from './styled'
import IconWrapper from '../shared/IconWrapper'
import { useStaticQuery, graphql } from 'gatsby'
import { getAlmaIconByName } from '../shared/getAlmaIconByName'
import { getLinkByIcon } from '../../utils/getLinkByIcon'

const ContactCards = props => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeFooter {
        sidebars {
          name
          widgets {
            type
            value {
              rendered
              items {
                title
                url
                object_id
              }
              name
            }
          }
        }
      }
    }
  `)

  const {
    wordpressGeneoneThemeFooter: { sidebars },
  } = data

  return sidebars.map(link => {
    if (link.name !== 'Contato') return false

    return link.widgets.map(widget => {
      if (widget.type !== 'footer_information') return null

      const widgetsValue = JSON.parse(widget.value.rendered)

      return widgetsValue.icon !== 'whatsapp' ? (
        <S.ContactCardPink size={props}>
          <S.ContentCardTitle href={getLinkByIcon(widgetsValue.icon, widgetsValue.title)}
            target="_blank">
            <IconWrapper Icon={getAlmaIconByName(widgetsValue.icon)} size="22" />
            <S.CardTitleContact>
              {widgetsValue.icon === 'phone' ? 'Ligue e fale conosco' : 'Envie um email'}
            </S.CardTitleContact>
          </S.ContentCardTitle>

          <S.ContactCardLink
            href={getLinkByIcon(widgetsValue.icon, widgetsValue.title)}
            target="_blank"
          >
            {widgetsValue.title}
          </S.ContactCardLink>

          <S.ContactCardText>{widgetsValue.text}</S.ContactCardText>

          <S.ContactCardText>{widgetsValue.subtext}</S.ContactCardText>
        </S.ContactCardPink>
      ) : (
        <S.ContactCardBlue size={props}>
          <S.ContentCardTitle  href={getLinkByIcon(widgetsValue.icon, widgetsValue.title)}
            target="_blank">
            <IconWrapper Icon={getAlmaIconByName(widgetsValue.icon)} size="22" />
            <S.CardTitleContact>Atendimento pelo WhatsApp</S.CardTitleContact>
          </S.ContentCardTitle>
          <S.ContactCardLink
            href={getLinkByIcon(widgetsValue.icon, widgetsValue.title)}
            target="_blank"
          >
            {widgetsValue.title}
          </S.ContactCardLink>

          <S.ContactCardText>{widgetsValue.text}</S.ContactCardText>

          <S.ContactCardText>{widgetsValue.subtext}</S.ContactCardText>
        </S.ContactCardBlue>
      )
    })
  })
}

export default ContactCards
